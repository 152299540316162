import { Button, VStack } from '@chakra-ui/react';
import { goToForm } from 'shared/lib/goToForm';
import { primaryProjects } from 'constants/projects/primary';
import { primaryLayout, secondaryLayout } from 'constants/projects/projectsLayoutConfigs';
import { secondaryProjects } from 'constants/projects/secondary';
import React, { memo } from 'react';
import ProjectCard from '~entities/ProjectCard';
import { ProjectsLayout } from 'shared/ProjectsLayout';
import GreetingsProjects from 'widgets/GreetingsProjects/GreetingsProjects';
import Reviews from 'widgets/Reviews';
import ContactUs from 'widgets/ContactUs';

const ProjectsPage = () => {
  return (
    <>
      <GreetingsProjects />
      <VStack spacing="40px" w="full">
        <VStack w="full" spacing={{ mobile: '12px', tablet: '16px', laptop: '20px' }}>
          <ProjectsLayout
            projects={primaryProjects}
            config={primaryLayout}
            ElementComponent={ProjectCard}
          />
          <ProjectsLayout
            projects={secondaryProjects}
            config={secondaryLayout}
            alter={true}
            ElementComponent={ProjectCard}
          />
        </VStack>
        <Button w="full" size="secondary" onClick={goToForm}>
          Get In Touch
        </Button>
      </VStack>
      <Reviews />
      <ContactUs />
    </>
  );
};

export default memo(ProjectsPage);

import { VStack, Text, Button, Stack, Box, Img, Flex } from '@chakra-ui/react';
import commonAssets from 'assets/common';
import { goToForm } from 'shared/lib/goToForm';
import React, { memo } from 'react';
import AnimatedText from 'shared/AnimatedText';

const GreetingsProjects = () => {
  return (
    <>
      <VStack spacing={{ mobile: '48px', tablet: '64px', laptop: '80px' }} align="start" w="100%">
        <Text as="h1">
          Products and Industry
          <br />
          Solutions
        </Text>
        <Button onClick={goToForm}>Get In Touch</Button>
      </VStack>
      <Stack
        direction={{ mobile: 'column', laptop: 'row' }}
        w="full"
        pos="relative"
        zIndex={800}
        gap={{ mobile: '28px', tablet: '40px', laptop: '20px' }}
      >
        <Box flex={1}>
          <Img
            h={{ mobile: '56px', tablet: 'fit-content' }}
            w={{ mobile: '44px', tablet: 'fit-content' }}
            src={commonAssets.icons.Aston}
            alt=""
          />
        </Box>

        <VStack spacing={{ mobile: '40px', tablet: '48px' }} flex={1} align="start">
          <AnimatedText as="h3" maxW={{ mobile: '500px', tablet: '720px' }}>
            There are over 400 projects for market leaders in our portfolio. Each project is
            valuable experience that we apply in solving new tasks
          </AnimatedText>
          <Flex gap={{ mobile: '8px', tablet: '20px' }} w="full" className="greetings__stats">
            <Box flex={1}>
              <Text as="h2">400+</Text>
              <Text>projects</Text>
            </Box>
            <Box flex={1}>
              <Text as="h2">17+</Text>
              <Text>years of experience</Text>
            </Box>
          </Flex>
        </VStack>
      </Stack>
    </>
  );
};

export default memo(GreetingsProjects);
